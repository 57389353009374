const envStrings = {
  scope: ['eb0f91d7-0179-411a-a357-0dfe7e9cff52'],
  authority:
    'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
  API_URL: 'https://test-vsgapi.vsgdover.com/api/',
  SubscriptionKey: '',

  /****DEV************/
  // API_URL:
  //   'https://boomi-us-dev.dovercorporation.com/vsg-product-registration/api/',
  // SubscriptionKey: 'eb7b1d5dfc014d1d8ce44fb49b03b89e', //dev

  /**********UAT********/
  // scope: ['7e9f9a1e-0e38-4eb4-929c-927c38b5a349'], //UAT
  // authority:
  //   'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
  // API_URL:
  //   'https://boomi-us-test.dovercorporation.com/vsg-product-registration/api/',
  // SubscriptionKey: '79671a62294e4e3aac6aa3e3f0a07fc9', //UAT

  /*******PROD***********/
  // scope: ['91b9fae7-2bff-435a-a24e-da9cafff89af'], //Prod
  // authority:
  //   'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
  // API_URL:
  //   'https://boomi-us.dovercorporation.com/vsg-product-registration/api/',
  // SubscriptionKey: '488babbf4754454fa6525641b4f5a537', //Prod
};
export default envStrings;

// const envStrings = {
//   scope: ['eb0f91d7-0179-411a-a357-0dfe7e9cff52'],
//   // scope: ['7e9f9a1e-0e38-4eb4-929c-927c38b5a349'], //UAT
//   // scope: ['91b9fae7-2bff-435a-a24e-da9cafff89af'], //Prod
//   authority:
//     'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
//   // authority:
//   //   'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
//   // authority:
//   //   'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
//   // API_URL: 'https://test-vsgapi.vsgdover.com/api/',
//   // API_URL: 'https://boomi-us-dev.dovercorporation.com/vsg-product-registration/api/',
//   API_URL: 'https://test-vsgapi.vsgdover.com/api/',
//   // API_URL:
//   //   'https://boomi-us-test.dovercorporation.com/vsg-product-registration/api/',
//   // API_URL:
//   //   'https://boomi-us.dovercorporation.com/vsg-product-registration/api/',
//   SubscriptionKey: '',
//   // SubscriptionKey: '488babbf4754454fa6525641b4f5a537', //Prod
//   // SubscriptionKey: '79671a62294e4e3aac6aa3e3f0a07fc9', //UAT
//   //  SubscriptionKey: 'eb7b1d5dfc014d1d8ce44fb49b03b89e', //dev
// };
// export default envStrings;
